<section class="about-me-section fontfam-poppins">
  <div class="content-wrapper">
    <div class="left-cont">
      <app-about-me-content></app-about-me-content>
    </div>
    <div data-aos="fade-left" class="right-cont">
      <img class="shape" src="/assets/images/shapes/about_me_colors.png" />
      <img class="picture" src="/assets/images/pics/me1.png" />
    </div>
  </div>
</section>
<section class="wave-transition">
  <img id="skills" src="/assets/images/shapes/wave.png" />
  <app-skills class="skills-section"></app-skills>
</section>

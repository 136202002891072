<h1 data-aos="fade-right">{{ lService.header["aboutMe"] }}</h1>
<p data-aos="fade-right">
  {{ lService.aboutMe["p1"] }}
</p>
<div class="pair">
  <div >
    <img data-aos="fade-right" src="/assets/images/icons/bulb_white.png" />
  </div>
  <p data-aos="fade-right">
    {{ lService.aboutMe["p2"] }}
  </p>
</div>
<div class="pair">
  <div >
    <img data-aos="fade-right" src="/assets/images/icons/puzzle_white.png" />
  </div>
  <p data-aos="fade-right">
    {{ lService.aboutMe["p3"] }}
  </p>
</div>

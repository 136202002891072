<section class="imprint fontfam-poppins">
  <div class="wrapper">
    <h1 class="heading">{{ lService.imprint["head"] }}</h1>

    <h2 class="tmg-heading">{{ lService.imprint["infoH"] }}</h2>
    <p class="address-tab">
      Sebastian Wolff<br />
      Klosterberg 2<br />
      OT: Oldisleben<br />
      06577 An der Schm&uuml;cke
    </p>

    <h2 class="contact-heading">{{ lService.imprint["contactH"] }}</h2>
    <p class="phone-mail">
      {{ lService.imprint["phone"] }} &#91;017664370553&#93;<br />
      {{ lService.imprint["mail"] }} bastiwolff432&#64;gmail.com
    </p>

    <p class="phone-mail">
      {{ lService.imprint["source"] }}
      <a class="no-style" href="https://www.e-recht24.de">e-recht24.de</a>
    </p>
  </div>
</section>

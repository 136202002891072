<section class="contact-section fontfam-poppins">
  <div class="c-head">
    <h2 data-aos="fade-left">{{ lService.contactSection["cHead"] }}</h2>
    <div data-aos="fade-left" class="contact-transition-line"></div>
  </div>
  <div class="cards-wrapper">
    <div class="cards-wrapped">
      <div class="contact-form-content">
        <div data-aos="fade-down-right" class="problem-section">
          <div class="problem-head">
            <div class="purple-transition"></div>
            <h4>{{ lService.contactSection["problemHead"] }}</h4>
          </div>
          <div class="problem-content">
            <p class="filler-phrase">
              {{ lService.contactSection["fillerP11"] }}
              <br class="break-point" />
              {{ lService.contactSection["fillerP12"] }}
              <br class="break-point" />
              {{ lService.contactSection["fillerP13"] }}
            </p>
            <p>
              {{ lService.contactSection["fillerP21"] }}
              <b>{{ lService.contactSection["fillerP22"] }}</b>
            </p>
          </div>
        </div>
        <div class="form-content">
          <form (ngSubmit)="onSubmit(contactForm)" #contactForm="ngForm">
            <div
              data-aos="flip-up"
              data-aos-anchor-placement="bottom-bottom"
              class="input-container"
            >
              <input
                autocomplete="off"
                class="form-control"
                [(ngModel)]="contactData.name"
                #name="ngModel"
                minlength="3"
                name="name"
                id="name"
                type="text"
                placeholder="{{ lService.contactSection['placeHolder1'] }}"
                required
              />
              @if (name.touched && name.invalid || isInvalidName) {
              <span class="message-warning">{{
                lService.contactSection["warn1"]
              }}</span>
              }
            </div>
            <div
              data-aos="flip-up"
              data-aos-anchor-placement="bottom-bottom"
              class="input-container"
            >
              <input
                autocomplete="off"
                class="form-control"
                #mail="ngModel"
                [(ngModel)]="contactData.email"
                name="email"
                id="email"
                type="text"
                placeholder="{{ lService.contactSection['placeHolder2'] }}"
                required
                pattern="[^@]+@[^\.]+\..+"
              />
              @if (mail.touched && mail.invalid || isInvalidMail) {
              <span class="message-warning">{{
                lService.contactSection["warn2"]
              }}</span>
              }
            </div>
            <div
              data-aos="flip-up"
              data-aos-anchor-placement="bottom-bottom"
              class="input-container"
            >
              <textarea
                class="form-control"
                #message="ngModel"
                minlength="4"
                [(ngModel)]="contactData.message"
                name="message"
                id="message"
                cols="30"
                rows="8"
                placeholder="{{ lService.contactSection['placeHolder3'] }}"
                required
              ></textarea>
              @if (message.touched && message.invalid || isInvalidMessage) {
              <span class="message-warning text-message">{{
                lService.contactSection["warn3"]
              }}</span>
              }
              <div *ngIf="messageSent" class="message-sent">
                <span [class.message]="messageSent">{{ lService.contactSection['mSent'] }}</span>
              </div>
            </div>
            <div
              data-aos="flip-up"
              data-aos-anchor-placement="bottom-bottom"
              class="checkbox-validation"
            >
              <div class="checkbox-cont">
                <input
                  [(ngModel)]="pPolicy"
                  type="checkbox"
                  name="pPolicy"
                  id="check"
                  required
                />
                <span class="checkbox"></span>
              </div>
              <p>
                {{ lService.contactSection["pPolicy1"] }}
                <a href="privacyPolicy">{{
                  lService.contactSection["pPolicy2"]
                }}</a>
                {{ lService.contactSection["pPolicy3"] }}
              </p>
            </div>
            <div
              data-aos="flip-up"
              data-aos-anchor-placement="bottom-bottom"
              class="submit-btn"
            >
              <input
                [disabled]="!contactForm.form.valid"
                type="submit"
                value="Send message :)"
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <div class="red-shape">
    <img
      class="shape-desktop"
      src="/assets/images/shapes/red_shape_contact.png"
    />
    <img
      class="shape-mobile"
      src="/assets/images/shapes/red_shape_contact_mobile.png"
    />
  </div>
  <a class="scroll-top" href="#"
    ><img src="/assets/images/icons/scroll_down_arrow_white.png"
  /></a>
</section>

<section class="fontfam-poppins">
  <div class="mobile-aME-pic-shape">
    <img class="shape" src="/assets/images/shapes/about_me_colors.png" />
    <img class="picture" src="/assets/images/pics/me1.png" />
  </div>
  <div class="mobile-wave">
    <img src="/assets/images/shapes/wave2.png" />
    
  </div>
  <app-about-me-content class="mobile-about-cont"></app-about-me-content>
  <app-skills id="skillsMobile" class="skills-section"></app-skills>
</section>

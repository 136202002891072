<section class="p-policy fontfam-poppins">
  <div class="wrapper">
    <h1>{{ lService.pPolicy_1["head"] }}</h1>
    <div>
      <h2>{{ lService.pPolicy_1["pGlance"] }}</h2>
      <h3>{{ lService.pPolicy_1["generalInfo"] }}</h3>
      <p>
        {{ lService.pPolicy_1["phraseGlance"] }}
      </p>
      <h3>{{ lService.pPolicy_1["dataCollection"] }}</h3>
      <h4>{{ lService.pPolicy_1["responsible"] }}</h4>
      <p>
        {{ lService.pPolicy_1["phraseResponsible"] }}
      </p>
      <h4>{{ lService.pPolicy_1["howCollect"] }}</h4>
      <p>
        {{ lService.pPolicy_1["howCollPhrase"] }}
      </p>
      <h4>{{ lService.pPolicy_1["dataUse"] }}</h4>
      <p>
        {{ lService.pPolicy_1["dataUsePhrase"] }}
      </p>
      <h4>{{ lService.pPolicy_1["dataRights"] }}</h4>
      <p>
        {{ lService.pPolicy_1["dataRightsPhrase"] }}
      </p>
      <h2>{{ lService.pPolicy_2["hosting"] }}</h2>
      <p>{{ lService.pPolicy_2["provider"] }}</p>
      <h3>All-Inkl</h3>
      <p>
        {{ lService.pPolicy_2["allinklPhrase1"] }}
        <a href="https://all-inkl.com/datenschutzinformationen/"
          >https://all-inkl.com/datenschutzinformationen</a
        >{{ lService.pPolicy_2["allinklPhrase2"] }}
      </p>
      <h2>{{ lService.pPolicy_3["mandatory"] }}</h2>
      <h3>{{ lService.pPolicy_3["dataProt"] }}</h3>
      <p>
        {{ lService.pPolicy_3["dataProtPhrase"] }}
      </p>
      <h3>{{ lService.pPolicy_3["controllerInfo"] }}</h3>
      <p>
        {{ lService.pPolicy_3["controllerPhrase1"] }} <br />
        <br />
        Sebastian Wolff <br />
        Klosterberg 2 <br />
        06577 An der Schmücke <br />
        {{ lService.pPolicy_3["controllerPhone"] }} 017664370553 <br />
        {{ lService.pPolicy_3["controllerMail"] }} bastiwolff432&#64;gmail.com
        <br />
        <br />
        {{ lService.pPolicy_3["controllerPhrase2"] }}
      </p>
      <h3>{{ lService.pPolicy_3["storageDuration"] }}</h3>
      <p>
        {{ lService.pPolicy_3["storagePhrase"] }}
      </p>
      <h3>
        {{ lService.pPolicy_3["legalBasis"] }}
      </h3>
      <p>
        {{ lService.pPolicy_3["legalBasisPhrase"] }}
      </p>
      <h3>
        {{ lService.pPolicy_3["dataTransfer"] }}
      </h3>
      <p>
        {{ lService.pPolicy_3["dataTransferPhrase"] }}
      </p>
      <h3>{{ lService.pPolicy_3["recipients"] }}</h3>
      <p>
        {{ lService.pPolicy_3["recipientsPhrase"] }}
      </p>
      <h3>{{ lService.pPolicy_3["revocation"] }}</h3>
      <p>
        {{ lService.pPolicy_3["revocationPhrase"] }}
      </p>
      <h3>
        {{ lService.pPolicy_3["specialCases"] }}
      </h3>
      <p>{{ lService.pPolicy_3["specialCasesPhrase"] }}.</p>
      <h3>{{ lService.pPolicy_3["lodgeRight"] }}</h3>
      <p>
        {{ lService.pPolicy_3["lodgeRightPhrase"] }}
      </p>
      <h3>{{ lService.pPolicy_3["portability"] }}</h3>
      <p>
        {{ lService.pPolicy_3["portabilityPhrase"] }}
      </p>
      <h3>{{ lService.pPolicy_3["correctionDeletion"] }}</h3>
      <p>
        {{ lService.pPolicy_3["correctionDeletionPhrase"] }}
      </p>
      <h3>{{ lService.pPolicy_3["restriction"] }}</h3>
      <p>
        {{ lService.pPolicy_3["restrictionPhrase"] }}
      </p>
      <ul>
        <li>
          {{ lService.pPolicy_3["restrictionLi1"] }}
        </li>
        <li>
          {{ lService.pPolicy_3["restrictionLi2"] }}
        </li>
        <li>
          {{ lService.pPolicy_3["restrictionLi3"] }}
        </li>
        <li>
          {{ lService.pPolicy_3["restrictionLi4"] }}
        </li>
        <li>
          {{ lService.pPolicy_3["restrictionLi5"] }}
        </li>
      </ul>
      <h2>{{ lService.pPolicy_4["collWeb"] }}</h2>
      <h3>{{ lService.pPolicy_4["contForm"] }}</h3>
      <p>
        {{ lService.pPolicy_4["contFormPhrase"] }}
      </p>
      <h3>{{ lService.pPolicy_4["inquiry"] }}</h3>
      <p>
        {{ lService.pPolicy_4["inquiryPhrase1"] }} <br />
        <br />
        {{ lService.pPolicy_4["inquiryPhrase2"] }}
      </p>
    </div>
  </div>
</section>

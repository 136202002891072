<section class="footer-section fontfam-poppins">
  <div class="f-transition-orange"></div>
  <div class="cards-wrapper">
    <div class="cards-wrapped">
      <div class="footer-content">
        <div class="left-cont">
          <a href="#" class="prename-container">
            <p>S</p>
            <p>e</p>
            <p>b</p>
            <p>a</p>
            <p>s</p>
            <p>t</p>
            <p class="i-letter-styling">i</p>
            <p>a</p>
            <p class="n-letter-styling">n</p>
          </a>
          <div class="impressum-wrapper">
            <a class="impressum-cont" href="impressum">Impressum</a>
            <div class="impressum-underline"></div>
          </div>
        </div>
        <div class="name-copyR">
          <p>&copy;</p>
          <p class="fullname">Sebastian Wolff 2024</p>
        </div>
        <div class="social-links-content">
          <a href="https://github.com/OptimismusPr1m3" [target]="'_blank'"
            ><img src="/assets/images/icons/github_icon_f.png"
          /></a>
          <a href="mailto:bastiwolff432@gmail.com"
            ><img src="/assets/images/icons/mail_icon_f.png" />
          </a>
          <a href="https://linkedin.com/in/sebastian-wolff-4409832b3" [target]="'_blank'"><img src="/assets/images/icons/linkedin_icon_f.png" /></a>
        </div>
        <div class="impressum-mobile">
          <a class="impressum-cont-mobile" href="impressum">Impressum</a>
          <div class="impressum-underline-mobile"></div>
        </div>
      </div>
    </div>
  </div>
</section>
